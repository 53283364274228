import React, { useEffect, useState } from "react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
    Emoji1Icon,
    Emoji2Icon,
    Emoji3Icon,
    Emoji4Icon,
    Emoji5Icon,
    FeedBackErrorIcon,
    FeedBackSuccessIcon
} from "../../assets/svg/icons";
import useFeedBack from "../../hooks/useFeedBack"; // Подключаем хук

const FeedbackForm = ({ title, pageName, mainPage }) => {
    const [isFocused, setIsFocused] = useState(false);


    const [message, setMessage] = useState(""); // Текстовое сообщение
    const [rating, setRating] = useState(0); // Рейтинг на основе выбора эмодзи
    const [showSuccessBlock, setsShowSuccessBlock] = useState(false);
    const [showErrorBlock, setsShowErrorBlock] = useState(false);
    const [errorMessage, setErrorMessage] = useState(""); // Для отображения ошибки

    const { executeRecaptcha } = useGoogleReCaptcha();
    const { fetchCardFeedBack, feedbackAnswer, feedbackLoading, feedbackError } = useFeedBack();

    // ❗ Проверяем, можно ли отправлять отзыв
    const isSubmitDisabled = !(rating > 0 || message.trim().length > 0); // Нужно хоть что-то

    useEffect(() => {
        if (feedbackAnswer) {
            setMessage("");
            setRating(0);
            setsShowSuccessBlock(true);

            setTimeout(() => {
                setsShowSuccessBlock(false);
            }, 2000);
        }
    }, [feedbackAnswer]);

    useEffect(() => {
        if (feedbackError) {
            console.error(feedbackError, 'Ошибка отправки');
            setErrorMessage("Произошла ошибка. Попробуйте снова.");
            setsShowErrorBlock(true);
        }
    }, [feedbackError]);

    const handleEmojiSelect = (index) => {
        setRating(index + 1); // Устанавливаем рейтинг (от 1 до 5)
    };



    const handleFocus = () => {
        // Прокрутка всего окна до самого низа
        window.scrollTo({
            top: document.body.scrollHeight + 300,
            behavior: "smooth", // плавная прокрутка
        });
    };


    useEffect(() => {
        if (isFocused) {
            setTimeout(()=>{
                handleFocus();
            },1000)
        }
    }, [isFocused]);



    const handleSubmit = async () => {
        if (!executeRecaptcha) {
            console.error("Recaptcha not загружена");
            return;
        }

        try {
            const captchaTokenGoogle = await executeRecaptcha("submit");

            // Формируем данные для отправки
            const feedbackData = {
                urlPage: window.location.href, // Текущая страница
                message,
                rating,
                section: `МиниАпп: ${pageName}`,
                captchaTokenGoogle,
            };

            // Отправляем данные с помощью хука
            await fetchCardFeedBack(feedbackData);
        } catch (err) {
            console.error("Ошибка при отправке отзыва:", err);
            setErrorMessage("Не удалось отправить. Попробуйте снова.");
            setsShowErrorBlock(true);
        }
    };

    if (showErrorBlock) {
        return (
            <div className={'feedback-success-wrapper'} style={{marginBottom: 30}}>
                <span className={'feedback-success-wrapper-span1 error'}>Сервис временно не работает</span>
                <FeedBackErrorIcon/>
                <span className={'feedback-success-wrapper-span2'}>{errorMessage}</span>
                <button onClick={() => { setsShowErrorBlock(false); setErrorMessage(""); }}>Понятно</button>
            </div>
        );
    } else if (showSuccessBlock) {
        return (
            <div className={'feedback-success-wrapper'} style={{marginBottom: 30}}>
                <span className={'feedback-success-wrapper-span1'}>Сообщение отправлено</span>
                <FeedBackSuccessIcon/>
                <span className={'feedback-success-wrapper-span2'}>Спасибо за обратную связь!</span>
                <button onClick={() => { setsShowSuccessBlock(false); }}>Хорошо</button>
            </div>
        );
    } else {
        return (
            <div style={{ paddingBottom:  isFocused ? mainPage ? 280 :  180 : 30 }}>
                <div className={"feedback-wrapper"}>
                    <h1>{title}</h1>

                    <div className={"feedback-wrapper-emoji"}>
                        {[Emoji1Icon, Emoji2Icon, Emoji3Icon, Emoji4Icon, Emoji5Icon].map((EmojiIcon, index) => (
                            <div
                                key={index}
                                className={`feedback-wrapper-emoji-item ${rating === index + 1 ? "selected" : ""}`}
                                onClick={() => handleEmojiSelect(index)}
                            >
                                <EmojiIcon />
                            </div>
                        ))}
                    </div>

                    <div style={{position:'relative', marginBottom: 20}}>
                     <textarea
                         onFocus={() => {
                             setIsFocused(true)
                             setTimeout(()=>{
                                 // alert()
                                 // handleFocus()
                             }, 200)
                         }}
                         onBlur={()=>{
                             setIsFocused(false)
                         }}
                         style={{marginBottom: 0}}
                         className={'feedback-textarea'}
                         placeholder="Ваш комментарий..."
                         value={message}
                         maxLength={300} // Обрезаем на 300 символах
                         onChange={(e) => setMessage(e.target.value.slice(0, 300))}
                     ></textarea>
                        <div style={{textAlign:'right', color: 'rgba(0,0,0,0.5)', fontSize: 14, marginTop: 5}}>{message.length}/300</div>
                    </div>


                    <button style={{opacity: isSubmitDisabled || feedbackLoading ? 0.5 : 1}}  onClick={handleSubmit} disabled={isSubmitDisabled || feedbackLoading}>
                        {feedbackLoading ? "Отправка..." : "Ответить"}
                    </button>

                    {errorMessage && <p className="feedback-error-message">{errorMessage}</p>}
                </div>
            </div>

        );
    }
};

// Оборачиваем компонент в GoogleReCaptchaProvider в корневом компоненте
const App = ({ pageName, title, handleFocus = () => {}, mainPage = false }) => {
    return (
        <FeedbackForm handleFocus={handleFocus} title={title} pageName={pageName} mainPage={mainPage}/>
    );
};

export default App;
