import React, { useEffect, useRef } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import Main from './pages/Main';
import MyCars from './pages/MyCars';
import MyOrders from './pages/MyOrders/List';
import MyOrdersSingle from './pages/MyOrders/Single';
import RegistrationsForServices from './pages/RegistrationsForServices/List';
import RegistrationsForServiceSingle from './pages/RegistrationsForServices/Single';
import WheelStorageList from './pages/WheelStorage/List';
import WheelStorageSingle from './pages/WheelStorage/Single';
import MyPurchasesList from './pages/MyPurchases/List';
import MyPurchasesSingle from './pages/MyPurchases/Single';
import MyDiscounts from './pages/MyDiscounts';
import Profile from "./pages/Profile";

import ExtendedWarrantyList from "./pages/ExtendedWarranty/List";
import ExtendedWarrantyActivateForm from "./pages/ExtendedWarranty/ActivateForm";

import Loader from "./components/Loader";
import useTranslation from "./hooks/useTranslation";

import { SingleProvider } from './context/SingleContext';
import Settings from "./pages/Settings";
import SliderComponent from "./components/SliderComponent";
import ErrorPage from "./pages/ErrorPage";
import ErrorModal from "./components/ErrorModal";
import LoginPage from "./pages/LoginPage";
import YandexMetrika from "./context/YandexMetrika"; // Импортируем контекст

function App() {
    // const { user, webApp } = useTelegram(); // Используем TelegramContext

    const [loader, setLoader] = React.useState(true);
    const [isLogin, setIsLogin] = React.useState(false);
    const [showErrorModal, setShowErrorModal] = React.useState(false);
    const [splashScreen, setSplashScreen] = React.useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    // Function to handle login
    const handleLogin = (page) => {
        setIsLogin(true);
        navigate(page); // Redirect to the main page
    };


    useEffect(() => {
        // Инициализация WebApp
        const webApp = window.Telegram?.WebApp;
        const platform = webApp.platform;

        webApp?.ready();
        // window.history.replaceState(null, null, window.location.pathname);

        alert(window.location.href)
        alert(JSON.stringify(platform))

        const isUnauthorizedEntry = () => {
            return !window.Telegram || !webApp?.initData || webApp.initData === "";
        };

        const fetchToken = async (url, initData) => {

            localStorage.removeItem('token');

            try {
                const response = await fetch(url, {
                    method: "POST", // Изменено с GET на POST
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ initData }), // Отправляем initData в теле запроса
                });

                if (response.status === 200) {
                    const data = await response.json();
                    console.log('Token:', data.token);

                    localStorage.setItem('token', data.token);

                    setIsLogin(true)
                    setLoader(false);
                    setShowErrorModal(false)

                    const searchParams = new URLSearchParams(location.search);
                    const redirectUrl = searchParams.get("redirect");

                    if (redirectUrl) {
                        try {
                            const url = new URL(redirectUrl);
                            console.log(url.pathname, 'url.pathname')
                            navigate(url.pathname)
                            console.log(redirectUrl, 'redirectUrl')
                        } catch (error) {
                            navigate('/main')
                            console.error("Ошибка обработки URL:", error);
                            return null;
                        }
                    } else {
                        console.log(window.location.pathname, 'window.location.pathname')
                        if (window.location.pathname == '/auth/' || window.location.pathname == '/auth') {
                            navigate('/main')
                            // setIsLogin(true)
                            // setLoader(false);
                            // setShowErrorModal(false)
                            // window.location.href = "/main"
                        }
                    }
                } else {
                    setIsLogin(false)
                    setShowErrorModal(true);
                    setLoader(false);
                    window.location.href = "https://t.me/KolesoRuBot";
                    throw new Error("Validation failed");
                }
            } catch (error) {
                console.error("Ошибка получения токена:", error);
            }
        };

        if (process.env.NODE_ENV === 'development')  {

            const initData = webApp.initData;
            const encodedInitData = encodeURIComponent(initData);

            const url = `${process.env.REACT_APP_GET_TOKEN_API_URL}/getTelegramUser`;
            fetchToken(url, encodedInitData);

        } else if (isUnauthorizedEntry()) {
            setShowErrorModal(true)
            window.location.href = "https://t.me/KolesoRuBot";

        } else if (process.env.NODE_ENV === 'production' )  {

            const initData = webApp.initData;
            const encodedInitData = encodeURIComponent(initData);
            // window.history.replaceState(null, null, window.location.pathname);

            const url = `${process.env.REACT_APP_GET_TOKEN_API_URL}/getTelegramUser`;
            fetchToken(url, encodedInitData);
        }
    }, []);


    // if (!isLogin && !loader)  {
    //     return <LoginPage  onLogin={handleLogin}/>
    // }

    // if (showErrorModal) {
    //     return <ErrorModal/>
    // }

    // Если лоадер активен, показываем его
    if (splashScreen) {
        return <SliderComponent onClose={()=>{setSplashScreen(false)}} />;
    }

    // Если лоадер активен, показываем его
    if (loader) {
        return <Loader />;
    }

    return (
        <SingleProvider>
            <YandexMetrika />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/main" element={<Main />} />
                <Route path="/login" element={<LoginPage  onLogin={handleLogin}/>} />
                {/*<Route path="/auth" element={<Main />} />*/}
                <Route path="/my-cars" element={<MyCars />} />

                <Route path="/my-orders" element={<MyOrders />} />
                <Route path="/my-orders-single" element={<MyOrdersSingle />} />

                <Route path="/registrations-for-services" element={<RegistrationsForServices />} />
                <Route path="/registrations-for-service-single" element={<RegistrationsForServiceSingle />} />

                <Route path="/wheel-storage-list" element={<WheelStorageList />} />
                <Route path="/wheel-storage-single" element={<WheelStorageSingle />} />

                <Route path="/my-purchases-list" element={<MyPurchasesList />} />
                <Route path="/my-purchases-single" element={<MyPurchasesSingle />} />

                <Route path="/my-discount" element={<MyDiscounts />} />
                <Route path="/my-profile" element={<Profile />} />

                <Route path="/extended-warranty-list" element={<ExtendedWarrantyList />} />
                <Route path="/extended-warranty-activate-form" element={<ExtendedWarrantyActivateForm />} />

                <Route path="/settings" element={<Settings />} />
                <Route path="/error-page" element={<ErrorPage />} />
            </Routes>
        </SingleProvider>

    );
}

export default App;
