import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../TelegramContext';
import {Link, useNavigate} from "react-router-dom";
import '../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";
import { BACKEND_URL } from '../../utils/constants';

import Loader from "../../components/Loader";
import useTranslation from "../../hooks/useTranslation";
import Header from "../../components/Header";
import {
    AutoIcon, CountIconBottom, CountIconTop, CrocodileIcon, Discount2Icon,
    DiscountNoActiveIcon, Emoji1Icon, Emoji2Icon, Emoji3Icon, Emoji4Icon, Emoji5Icon, MyOrdersIcon, MyPurchasesIcon,
    RedMessIcon, RegistrationForServicesIcon,
    RightArrowIcon,
    SettingsIcon,
    UserIcon, WheelStorageListIcon
} from "../../assets/svg/icons";
import useUserProfile from "../../hooks/useUserProfile";
// import topImage from "../../assets/img/main/top.png";
import topImage from "../../assets/img/main/header_main.png";

import FeedbackForm from "../../components/FeedbackForm";
import useMyCars from "../../hooks/useMyCars";
import {useUserOrders} from "../../hooks/useUserOrders";
import CountdownTimer from "../../components/CountdownTimer";
import axios from "axios";
import {useWilStorage} from "../../hooks/useWillStorage";
import useMyCard from "../../hooks/useMyCard";
import useMainBanner from "../../hooks/useMainBanner";



import {useSingleData} from "../../context/SingleContext";
import CloseAndSupportButton from "../../components/CloseAndSupportButton";

const authorizationToken = process.env.REACT_APP_WIDGET_AUTHORIZATION_TOKEN;

function Main() {

    const { user, triggerVibration, webApp  } = useTelegram();
    const navigate = useNavigate();
    const {translation} = useTranslation();

    const { myCarsAnswer, loadingMyCars, errorMyCars, fetchUserCars } = useMyCars();
    const {orders, loading, error, fetchUserOrders} = useUserOrders();
    const { profileAnswer, loadingProfile, errorProfile, fetchUserProfile} = useUserProfile();
    const [dataToServices, setDataToServices] = useState(true);
    const {wilStorageAnswer, loadingWilStorage, errorWilStorage, fetchWilStorage} = useWilStorage();
    const {
        discountAnswer, loadingDiscount, errorDiscount, fetchDiscount,
        cardAnswer, loadingCard, errorCard, fetchCard ,
    } = useMyCard();
    const {
        mainBannerAnswer,
        mainBannerLoading,
        mainBannerError,
        fetchMainBanner
    } = useMainBanner();

    const [showSlider, setShowSlider] = useState(true);

    const { selectedSingleData, setSelectedSingleData } = useSingleData(); // Достаем функцию для установки выбранного заказа


    useEffect(()=>{
        fetchUserCars()
        fetchMainBanner()
    }, [])

    useEffect(()=>{
        if (mainBannerAnswer) {
            console.log(mainBannerAnswer?.data[0], 'mainBannerAnswer')
        }
    }, [mainBannerAnswer])


    useEffect(()=>{

        let slider_ = localStorage.getItem('slider')
        if (slider_) {
            setShowSlider(false)
        } else {
            setShowSlider(true)
        }
    }, [])


    useEffect(()=>{
        if (profileAnswer) {

            fetchCard( profileAnswer?.data?.phone)
            postDataToServicesAPI(profileAnswer?.data?.phone);

        }
    }, [profileAnswer])


    useEffect(()=>{
        if (cardAnswer) {
            console.log(cardAnswer?.data?.cardNumber, 'cardAnswercardNumber')
            console.log(cardAnswer, 'cardAnswer')
        }
    }, [cardAnswer])


    useEffect(()=>{
        if (errorCard) {
            console.log(errorCard, 'errorCard')
        }
    }, [errorCard])


    function formatPhoneNumber(phoneNumber) {
        // Удаляем все нецифровые символы
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Проверяем, что номер состоит из 11 цифр
        if (cleaned.length !== 11) {
            throw new Error('Номер телефона должен содержать 11 цифр');
        }

        // Форматируем номер
        const countryCode = cleaned.slice(0, 1); // Код страны
        const areaCode = cleaned.slice(1, 4); // Код города
        const firstPart = cleaned.slice(4, 7); // Первая часть номера
        const secondPart = cleaned.slice(7, 9); // Вторая часть номера
        const thirdPart = cleaned.slice(9, 11); // Третья часть номера

        return `+${countryCode}(${areaCode})${firstPart}-${secondPart}-${thirdPart}`;
    }

    const postDataToServicesAPI = async (phone) => {

        try {
            // const phone = localStorage.getItem('phone')
            if (phone) {
                let phone_ = formatPhoneNumber(phone)
                console.log(phone_, 'phone_')
                const response = await axios.post(`${process.env.REACT_APP_SERVICES_WIDGET_API_URL}/user/services`, {phone: phone_}, {
                    headers: {
                        'Content-Type': 'application/json', // Установите правильный тип контента, если требуется
                        'Authorization': `Bearer ${authorizationToken}`, // Если нужен токен, добавьте его здесь
                    }
                });

                const filteredOrders = response.data.filter(order => order.status != "Отменена");

                console.log('response.data:',response.data);
                console.log('filteredServices:',filteredOrders);
                setDataToServices(filteredOrders)

            }

        } catch (error) {
            console.error('Error posting data:', error.response?.data || error.message);
            throw error.response?.data || error;
        }

    };

    function getDayOfWeekInRussian(dateStr) {
        // Преобразуем строку в объект Date
        const dateObj = new Date(dateStr);

        // Массив названий дней недели на русском
        const daysOfWeek = ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"];

        // Получаем название дня недели
        return daysOfWeek[dateObj.getDay()];
    }



    function formatDateToRussian(dateStr) {
        // Преобразуем строку в объект Date
        const dateObj = new Date(dateStr);

        // Массив названий месяцев на русском
        const months = [
            "января", "февраля", "марта", "апреля", "мая", "июня",
            "июля", "августа", "сентября", "октября", "ноября", "декабря"
        ];

        // Получаем день, месяц и год
        const day = dateObj.getDate();
        const month = months[dateObj.getMonth()];
        const year = dateObj.getFullYear();

        // Форматируем строку
        return `${day} ${month} ${year} г.`;
    }


    function isDateInPast(dateString) {
        // Функция для перевода месяца с русского на английский
        const convertMonth = (month) => {
            const months = {
                'января': 'January',
                'февраля': 'February',
                'марта': 'March',
                'апреля': 'April',
                'мая': 'May',
                'июня': 'June',
                'июля': 'July',
                'августа': 'August',
                'сентября': 'September',
                'октября': 'October',
                'ноября': 'November',
                'декабря': 'December'
            };
            return months[month];
        };

        // Разделяем строку на день, месяц и год
        const [day, month, year] = dateString.replace(" г.", "").split(" ");
        const englishMonth = convertMonth(month); // Переводим месяц

        // Создаем объект даты
        const givenDate = new Date(`${englishMonth} ${day}, ${year}`);
        const today = new Date(); // Сегодняшняя дата

        console.log(today , givenDate)
        // Сравниваем с сегодняшней датой
        return today > givenDate;
    }


    const handleFocus = () => {
        // Прокрутка всего окна до самого низа
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth", // плавная прокрутка
        });
    };


    if ( loadingProfile || loadingMyCars || loading || loadingDiscount || loadingCard) {
        return (
            <Loader/>
        )
    }

    return (
        <div className="wrapper " style={{padding: 0}}>

            <Header autoImage={topImage} isMain={true}/>

            <div className={'padding-block'}>

                <div className={'actions-wrapper'}>

                    <Link to={'/my-profile'} className={'actions-item-wrapper left'}>
                        <UserIcon/>
                        <span>+{profileAnswer?.data?.phone}</span>
                        <RightArrowIcon/>
                    </Link>

                    {/*<Link to={'my-cars'} className={'actions-item-wrapper right'}>*/}
                    {/*    <AutoIcon/>*/}
                    {/*    <span>Авто</span>*/}
                    {/*</Link>*/}

                    <Link to={'/settings'} className={'actions-item-wrapper right'}>
                        <SettingsIcon/>
                    </Link>
                    {/*<CloseAndSupportButton/>*/}

                </div>


                {cardAnswer?.data?.cardNumber && profileAnswer?.data?.phone &&
                    <CountdownTimer phone={profileAnswer?.data?.phone}/>
                }


                <div className={'list-wrapper'}>

                    {orders?.data && orders?.data?.length > 0 &&
                        (() => {
                            const firstOrderTypeZero = orders.data.find(order => order.orderType === 0);
                            return firstOrderTypeZero ? (
                                <Link
                                    to={'/my-orders-single'}
                                    className={'list-item'}
                                    onClick={() => {
                                        setSelectedSingleData(firstOrderTypeZero)
                                    }}
                                >
                                    <h2 className={'list-item-title'}>Новый заказ</h2>
                                    <div className={'list-item-center'}>
                                        <span>№ {firstOrderTypeZero.orderNumber}</span>
                                        <span>от {firstOrderTypeZero.orderDate}</span>
                                    </div>
                                    <div className={'list-item-bottom'}>
                                        <span>на сумму:</span>
                                        <span>{firstOrderTypeZero.totalSumOrder} ₽</span>
                                    </div>
                                </Link>
                            ) : null;
                        })()
                    }



                    {dataToServices && dataToServices.length > 0 &&
                        <Link
                            to={'/registrations-for-service-single'}
                            className={'list-item'}
                            onClick={() => {
                                setSelectedSingleData(dataToServices[0])
                            }}
                        >
                            <h2 className={'list-item-title'}>Ближайшая запись</h2>
                            <div className={'list-item-center'}>
                                <span>Шиномонтаж</span>
                                <span>{formatDateToRussian(dataToServices[0]?.date?.date)}</span>

                            </div>
                            <div className={'list-item-bottom'}>
                                <span>{getDayOfWeekInRussian(dataToServices[0]?.date?.date)}</span>
                                <span className={'active'}>{dataToServices[0]?.date?.time}</span>
                            </div>
                        </Link>
                    }


                    {wilStorageAnswer?.data && wilStorageAnswer?.data.length > 0 &&
                        <div className={'list-item'}>

                            {isDateInPast(wilStorageAnswer?.data[0]?.dataEnd) &&
                                <div className={'red-message'}>
                                    <RedMessIcon className={'red-message-icon'}/>
                                    нужно забрать
                                </div>
                            }

                            <h2 className={'list-item-title'}>Хранение колёс</h2>
                            <div className={'list-item-center'}>
                                <span className={isDateInPast(wilStorageAnswer?.data[0]?.dataEnd) ? 'active' : ''}>№ {wilStorageAnswer?.data[0]?.partyCode}</span>
                                <span>до {wilStorageAnswer?.data[0]?.dataEnd}</span>
                            </div>
                            <div className={'list-item-bottom'}>
                                <p>{wilStorageAnswer?.data[0]?.equipment}, {wilStorageAnswer?.data[0]?.quantity} шт. </p>
                            </div>
                        </div>

                    }


                </div>


                <div className={'categories-wrapper'}>

                    <div className={'categories-wrapper-top'}>
                        <Link to={'/my-cars'} className={'categories-item'} style={{marginRight: 17}}>

                            {myCarsAnswer?.data && myCarsAnswer?.data?.length > 0 &&
                                <div className={'categories-item-count-block'}>
                                    <CountIconTop className={'categories-item-count-icon-top'}/>
                                    <CountIconBottom className={'categories-item-count-icon-bottom'}/>

                                    <span>{myCarsAnswer?.data ? myCarsAnswer?.data.length : ''}</span>
                                </div>
                            }


                            <div className={'categories-item-top'}>
                                <RegistrationForServicesIcon/>
                            </div>

                            <p className={'categories-item-title'}>
                                Мои авто
                            </p>

                        </Link>


                        <Link to={'/registrations-for-services'} className={'categories-item'}>
                            {dataToServices && dataToServices.length > 0 &&
                                <div className={'categories-item-count-block'}>
                                    <CountIconTop className={'categories-item-count-icon-top'}/>
                                    <CountIconBottom className={'categories-item-count-icon-bottom'}/>
                                    <span>{dataToServices.length > 0 ? dataToServices.length : ''}</span>
                                </div>
                            }


                            <div className={'categories-item-top'}>
                                <RegistrationForServicesIcon/>
                            </div>

                            <p className={'categories-item-title'}>
                                Записи на услуги
                            </p>

                        </Link>
                    </div>

                    <div className={'categories-wrapper-center'}>


                        <Link to={'/my-orders'} className={'categories-item'} style={{marginRight: 17}}>

                            {orders?.data && orders?.data?.filter(order => order.orderType == 0).length > 0 &&
                                <div className={'categories-item-count-block'}>
                                    <CountIconTop className={'categories-item-count-icon-top'}/>
                                    <CountIconBottom className={'categories-item-count-icon-bottom'}/>

                                    <span>{orders?.data && orders?.data?.filter(order => order.orderType == 0).length > 0 ? orders?.data?.filter(order => order.orderType == 0).length : ''}</span>
                                </div>
                            }


                            <div className={'categories-item-top'}>
                                <MyOrdersIcon/>
                            </div>

                            <p className={'categories-item-title'}>
                                Мои заказы
                            </p>

                        </Link>


                        <Link to={'/wheel-storage-list'} className={'categories-item'} style={{marginRight: 17}}>
                            {wilStorageAnswer?.data && wilStorageAnswer?.data.length > 0 &&
                                <div className={'categories-item-count-block'}>
                                    <CountIconTop className={'categories-item-count-icon-top'}/>
                                    <CountIconBottom className={'categories-item-count-icon-bottom'}/>

                                    <span>{wilStorageAnswer?.data && wilStorageAnswer?.data.length > 0 ? wilStorageAnswer?.data.length : ''}</span>
                                </div>
                            }


                            <div className={'categories-item-top'}>
                                <WheelStorageListIcon/>
                            </div>

                            <p className={'categories-item-title'}>
                                Хранение колёс
                            </p>

                        </Link>

                        <Link to={'/my-purchases-list'} className={'categories-item'}>

                            {orders?.data && orders?.data?.filter(order => order.orderType == 1).length > 0 &&
                                <div className={'categories-item-count-block'}>
                                    <CountIconTop className={'categories-item-count-icon-top'}/>
                                    <CountIconBottom className={'categories-item-count-icon-bottom'}/>

                                    <span>{orders?.data && orders?.data?.filter(order => order.orderType == 1).length > 0 ? orders?.data?.filter(order => order.orderType == 1).length : ''}</span>
                                </div>
                            }

                            <div className={'categories-item-top'}>
                                <MyPurchasesIcon/>
                            </div>

                            <p className={'categories-item-title'}>
                                Мои покупки
                            </p>
                        </Link>
                    </div>

                    <div className={'categories-wrapper-bottom'}>

                        <Link to={'/my-discount'} className={'categories-item categories-item-discount'} style={{marginRight: 17, background: "#4E2B96"}}>
                            <div className={'categories-item-top'} style={{color: 'white'}}>
                                Мои скидки
                            </div>

                            <div className={'categories-item-discount-wrapper'}>

                                {!discountAnswer?.data?.cardNum ?
                                    <button> Как получить</button>

                                    :
                                    <div style={{background: "#fff", padding: '4px 7px'}}>
                                        <p>{discountAnswer?.data?.serv}% на услуги</p>
                                        <p>{discountAnswer?.data?.goods}% на товары</p>
                                    </div>
                                }


                            </div>

                            <Discount2Icon className={'categories-item-discount-icon1'}/>
                            <CrocodileIcon className={'categories-item-discount-icon2'}/>
                        </Link>

                        <Link to={'/extended-warranty-list'} className={'categories-item'}>

                            <div className={'categories-item-top'}>
                                Гарантия
                            </div>

                            <button
                                onClick={(event)=>{
                                    event.preventDefault(); // Предотвращает переход по ссылке, если кнопка нажата
                                    navigate('/extended-warranty-list?showModal=true');
                                }}>
                                Активировать
                            </button>
                        </Link>

                    </div>

                </div>


                <div className="banner-wrapper">
                    <h1>Выгодно</h1>

                    <div
                        // href={`${process.env.REACT_APP_DOMAIN_URL}${mainBannerAnswer?.data[0]?.href}`}
                        onClick={()=>{
                            const pageUrl_ = `${process.env.REACT_APP_DOMAIN_URL}${mainBannerAnswer?.data[0]?.href}`
                            window.Telegram.WebApp.openLink(pageUrl_);
                        }}
                        className="banner"
                    >
                        {mainBannerAnswer?.data.length > 0  &&
                            <img src={`${process.env.REACT_APP_DOMAIN_URL}${mainBannerAnswer?.data[0]?.mobileSrc}`} alt=""/>
                        }
                        {/*<img src={require("../../assets/img/main/baner.webp")} alt=""/>*/}
                    </div>
                </div>

                <FeedbackForm
                    mainPage={true}
                    handleFocus={handleFocus}
                    title={'Насколько удобен личный кабинет?'}
                    pageName={'Главная страница'}
                />

                <div style={{width: '100%', height: 100,}}>

                </div>

            </div>

        </div>
    );
}

export default Main;
